import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout.js';
import HomePage from './pages/HomePage.js'; // Import the HomePage component
import AboutPage from './pages/AboutPage.js';
import LoginPage from './pages/LoginPage.js';
import RegisterPage from './pages/RegisterPage.js';
import UploadPage from './pages/UploadPage.js'; // Import the UploadPage
import GalleryPage from './pages/GalleryPage.js'; // Import GalleryPage
import { AuthProvider } from './contexts/AuthContext'; // Import the AuthProvider
import ProtectedRoute from './routes/ProtectedRoute'; // Import the ProtectedRoute

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            {/* Protected routes for authenticated users */}
            <Route path="/" element={<ProtectedRoute element={<HomePage />} />} />
            <Route path="/AboutPage" element={<ProtectedRoute element={<AboutPage />} />} />
            <Route path="/UploadPage" element={<ProtectedRoute element={<UploadPage />} />} /> {/* New protected route for upload */}
            <Route path="/GalleryPage" element={<ProtectedRoute element={<GalleryPage />} />} /> {/* New gallery route */}

            {/* Public routes */}
            <Route path="/LoginPage" element={<LoginPage />} />
            <Route path="/RegisterPage" element={<RegisterPage />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
