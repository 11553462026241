import React, { useState } from 'react';
import { storage, db } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import './UploadPage.css'; // Import CSS for styling

const UploadPage = () => {
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!image) return;

    const imageRef = ref(storage, `uploads/${uuidv4()}`);
    await uploadBytes(imageRef, image);
    const imageUrl = await getDownloadURL(imageRef);

    await addDoc(collection(db, 'uploads'), {
      text,
      imageUrl,
      timestamp: serverTimestamp(),
    });

    setText('');
    setImage(null);
    alert('Upload successful!');
  };

  return (
    <div className="outer-container">
      <div className="upload-container">
        <h2 className="upload-title">Upload an Image and Text</h2>
        <input
          type="text"
          placeholder="Enter text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          className="upload-input"
        />
        <input type="file" onChange={handleImageChange} className="file-input" />
        <button onClick={handleUpload} className="upload-button">Upload</button>
      </div>
    </div>
  );
};

export default UploadPage;
