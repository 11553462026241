import React from 'react';
import './AboutPage.css';

const AboutPage = () => {
  return (
    <div className="outer-container">
      <div className="about-container">
        <h1 className="about-title">About NriPool</h1>
        <p className="about-content">
          NriPool is a platform dedicated to empowering people abroad to invest seamlessly and securely in India. Our mission is to connect global investors with trusted opportunities across real estate, startups, and more, enabling meaningful contributions to India's growth.
        </p>
        <h2 className="about-subtitle">Our Vision</h2>
        <p className="about-content">
          We believe distance shouldn’t limit investment potential. NriPool makes it easy for NRIs and global investors to participate in India's growth story with confidence.
        </p>
        <h2 className="about-subtitle">What We Offer</h2>
        <ul className="about-list">
          <li>Curated investment options across high-growth sectors</li>
          <li>Expert guidance and market insights</li>
          <li>Secure, compliant investment process</li>
          <li>Access to a network of like-minded investors</li>
        </ul>
        <p className="about-content">
          Join NriPool to invest in India's future and make an impact from anywhere in the world.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
