// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC1bpu-mI_Tn7m2uQqJg3J4nhgKzCTEmPE",
    authDomain: "nripool-c311a.firebaseapp.com",
    projectId: "nripool-c311a",
    storageBucket: "nripool-c311a.appspot.com",
    messagingSenderId: "429910640743",
    appId: "1:429910640743:web:7e97318623aea5d700acc2",
    measurementId: "G-RPR935030X"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the Firebase Auth object
export const auth = getAuth(app);

export const db = getFirestore(app);
export const storage = getStorage(app); // Make sure this line is present