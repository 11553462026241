import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import axios from 'axios';
import { isMobile, isTablet, isDesktop, osName, browserName } from 'react-device-detect';
import './Header.css';

const Header = () => {
  const { isAuthenticated, currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    city: '',
    region: '',
    country: '',
    ip: '',
    error: null,
  });

  const [lastLoggedIp, setLastLoggedIp] = useState(null);

  const infoRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoRef.current && !infoRef.current.contains(event.target)) setShowInfo(false);
      if (menuRef.current && !menuRef.current.contains(event.target)) setMenuOpen(false);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchIpLocation = async () => {
    try {
      const response = await axios.get(`https://ipapi.co/json/`); // Direct URL for IP API
      const { city, region, country, ip } = response.data;
      setLocation((prev) => ({ ...prev, city, region, country, ip, error: null }));
    } catch (error) {
      setLocation((prev) => ({ ...prev, error: 'Unable to fetch location from IP' }));
    }
  };

  useEffect(() => {
    fetchIpLocation();
  }, []);

  useEffect(() => {
    const logUserLogin = async () => {
      if (isAuthenticated && currentUser && location.ip && location.ip !== lastLoggedIp) {
        const userData = {
          userId: currentUser.uid,
          displayName: currentUser.displayName || currentUser.email,
          device: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : 'Unknown',
          operatingSystem: osName,
          browser: browserName,
          ip: location.ip,
          city: location.city || null,
          region: location.region || null,
          country: location.country || null,
          latitude: location.latitude ?? null,
          longitude: location.longitude ?? null,
          timestamp: new Date().toISOString(),
        };
        try {
          const userLoginsCollection = collection(db, `userLogins/${currentUser.uid}/logins`);
          await addDoc(userLoginsCollection, userData);
          setLastLoggedIp(location.ip);
        } catch (error) {
          console.error('Error logging user login:', error);
        }
      }
    };
    logUserLogin();
  }, [isAuthenticated, currentUser, location, lastLoggedIp]);

  const handleLogout = () => {
    logout();
    navigate('/LoginPage');
    setMenuOpen(false);
    setLastLoggedIp(null);
  };

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleInfo = () => setShowInfo(!showInfo);

  return (
    <header className="header">
      <nav className="nav">
        <div className="logo-container">
          <div className="info-icon" onClick={toggleInfo}>
            ℹ️
          </div>
          <Link to="/" className="logo" onClick={() => setMenuOpen(false)}>
            NriPool
          </Link>
        </div>
        <div className="right-container">
          <div className="user-info">
            {isAuthenticated ? (
              currentUser?.displayName || currentUser?.email
            ) : (
              <Link to="/LoginPage" className="login-link">
                Login
              </Link>
            )}
          </div>
          <div className="hamburger" onClick={toggleMenu}>
            &#9776;
          </div>
        </div>

        {menuOpen && (
          <ul className="nav-list" ref={menuRef}>
            <li><Link to="/" className="nav-link" onClick={() => setMenuOpen(false)}>Home</Link></li>
            <li><Link to="/AboutPage" className="nav-link" onClick={() => setMenuOpen(false)}>About</Link></li>
            <li><Link to="/UploadPage" className="nav-link" onClick={() => setMenuOpen(false)}>Upload</Link></li>
            <li><Link to="/GalleryPage" className="nav-link" onClick={() => setMenuOpen(false)}>Gallery</Link></li>
            {isAuthenticated && (
              <li><button onClick={handleLogout} className="logout-button">Logout</button></li>
            )}
          </ul>
        )}
      </nav>

      {showInfo && (
        <div className="info-popup" ref={infoRef}>
          <div className="device-info">
            <p>{isMobile && "You are using a Mobile device"}</p>
            <p>{isTablet && "You are using a Tablet"}</p>
            <p>{isDesktop && "You are using a Desktop"}</p>
            <p>Operating System: {osName}</p>
            <p>Browser: {browserName}</p>
          </div>
          <div className="location-info">
            {location.error ? (
              <p>{location.error}</p>
            ) : location.city && location.region && location.country ? (
              <p>You are browsing from: {location.city}, {location.region}, {location.country}</p>
            ) : location.ip ? (
              <p>Your IP address: {location.ip}</p>
            ) : (
              <p>Unable to detect location.</p>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
