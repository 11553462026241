import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';  // Import Firebase auth object

// Create the AuthContext
const AuthContext = createContext();

// Create a hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);  // Store the Firebase user object
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check localStorage for the initial authentication status
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  // Effect to update localStorage whenever isAuthenticated state changes
  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  // Firebase authentication state listener
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);  // Set the Firebase user object
      setIsAuthenticated(!!user);  // Set isAuthenticated based on the user object
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);

  // Simulate login (this can be replaced with Firebase login if necessary)
  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true'); // Save to localStorage
  };

  // Log out using Firebase
  const logout = () => {
    signOut(auth);  // Log out from Firebase
    setCurrentUser(null);
    setIsAuthenticated(false);
    localStorage.setItem('isAuthenticated', 'false'); // Clear localStorage
  };

  // Synchronize state across tabs using the storage event
  useEffect(() => {
    const handleStorageChange = () => {
      const authStatus = localStorage.getItem('isAuthenticated') === 'true';
      setIsAuthenticated(authStatus);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
