import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';  // Import the AuthContext hook

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();

  // If the user is not authenticated, redirect them to the login page
  if (!isAuthenticated) {
    return <Navigate to="/LoginPage" />;
  }

  // If authenticated, return the requested element
  return element;
};

export default ProtectedRoute;
