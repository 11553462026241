import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './GalleryPage.css';

const GalleryPage = () => {
  const [uploads, setUploads] = useState([]);
  const [zoomedImage, setZoomedImage] = useState(null);

  useEffect(() => {
    const fetchUploads = async () => {
      const uploadsCollection = collection(db, 'uploads');
      const uploadsSnapshot = await getDocs(uploadsCollection);
      const uploadsData = uploadsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUploads(uploadsData);
    };

    fetchUploads();
  }, []);

  const handleImageClick = (imageUrl) => {
    setZoomedImage(imageUrl);
  };

  const closeZoom = () => {
    setZoomedImage(null);
  };

  return (
    <div className="outer-container">
      <div className="gallery">
        <h2 className="gallery-title">Gallery</h2>
        <div className="gallery-grid">
          {uploads.map((upload) => (
            <div
              key={upload.id}
              className="gallery-item"
              onClick={() => handleImageClick(upload.imageUrl)}
              style={{
                backgroundImage: `url(${upload.imageUrl})`,
              }}
            >
              <div className="gallery-item-text">
                <p>{upload.text}</p>
              </div>
            </div>
          ))}
        </div>

        {zoomedImage && (
          <div className="zoom-overlay" onClick={closeZoom}>
            <div
              className="zoomed-image"
              style={{
                backgroundImage: `url(${zoomedImage})`,
              }}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GalleryPage;
