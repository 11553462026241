import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext'; 
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'; 
import { auth } from '../firebase';
import './LoginPage.css'; 

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      login();
      navigate('/');
    } catch (error) {
      console.error("Login failed:", error);
      alert('Invalid email or password. Please try again.');
    }
  };

  const handleCreateAccount = () => {
    navigate('/RegisterPage');
  };

  const handlePasswordReset = async () => {
    if (!email) {
      alert('Please enter your email to reset the password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      alert('Password reset email has been sent. Please check your inbox.');
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert('Failed to send password reset email. Please check your email address.');
    }
  };

  return (
    <div className="outer-container">
      <div className="login-container">
        <h1 className="login-title">Login</h1>
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="login-input"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Login</button>
        </form>
        <p className="login-text">Don't have an account?</p>
        <button onClick={handleCreateAccount} className="secondary-button">Create Account</button>
        <p className="login-text">Forgot your password?</p>
        <button onClick={handlePasswordReset} className="secondary-button">Reset Password</button>
        {resetEmailSent && <p className="reset-message">Password reset email has been sent. Please check your inbox.</p>}
      </div>
    </div>
  );
};

export default LoginPage;
