// Layout.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../contexts/AuthContext';

const Layout = ({ children }) => {
  const { currentUser } = useAuth();

  return (
    <div style={styles.container}>
      <Header user={currentUser} />
      <main style={styles.content}>{children}</main>
      <Footer />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
};

export default Layout;
