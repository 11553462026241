import React from 'react';
import './HomePage.css';

const HomePage = () => {
  return (
    <div className="outer-container">
      <div className="home-container">
        <h1 className="home-title">Welcome to NriPool</h1>
        <p className="home-content">
          NriPool connects NRIs worldwide to pool resources and invest in India’s growing opportunities. Together, we make impactful investments across real estate, infrastructure, and tech.
        </p>  
        <h2 className="home-subtitle">Why Choose NriPool?</h2>
        <p className="home-content">
          Join a global community, access curated projects, and invest with transparency and ease. NriPool makes it simple to grow your impact and portfolio.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
