import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import './RegisterPage.css'; // Import the CSS file for styling

const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      alert('Registration successful! You can now log in.');
      navigate('/login');
    } catch (error) {
      console.error("Error registering new user: ", error);
      alert(error.message);
    }
  };

  return (
    <div className="outer-container">
      <div className="login-container">
        <h1 className="login-title">Create a New Account</h1>
        <form onSubmit={handleRegister} className="login-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="login-input"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            className="login-input"
            required
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your password"
            className="login-input"
            required
          />
          <button type="submit" className="login-button">Register</button>
        </form>
        <button onClick={() => navigate('/LoginPage')} className="secondary-button">Back to Login</button>
      </div>
    </div>
  );
};

export default RegisterPage;
